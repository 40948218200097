import React from 'react';
import {
  ComponentPreview,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  Section,
} from 'docComponents';
import { Subhead } from 'hudl-uniform-ui-components';
import subheadPreviewData from '../../../../data/previews/subhead.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Subhead"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Type" tierThree="Subhead" />

      <PageNavigation>
        <PageNavigationLink>Size</PageNavigationLink>
        <PageNavigationLink>Microcopy</PageNavigationLink>
        <PageNavigationLink>Platform</PageNavigationLink>
      </PageNavigation>

      <Section title="Size">
        <Paragraph>
          Consider the piece of interface in question as you choose between
          these three.
        </Paragraph>
        <ComponentPreview
          name="SubheadSize"
          layout="default"
          previewData={subheadPreviewData.levels}>
          <Subhead>We help you win.</Subhead>
        </ComponentPreview>
      </Section>

      <Section title="Microcopy">
        <Paragraph>
          Use the{' '}
          <Link href="/words/content-elements/labels-non-label-text">
            UI label guidelines
          </Link>{' '}
          when typesetting subheads.
        </Paragraph>
      </Section>

      <Section title="Platform">
        <PlatformTable footerMessage="No guidelines exist for Windows (yet).">
          <PlatformTableRow platform="web" />
          <PlatformTableRow platform="apple" />
          <PlatformTableRow platform="android" />
        </PlatformTable>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
